import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Button, Input, Modal, Checkbox, HtmlInputrops } from 'semantic-ui-react';
import { Trans, withTranslation } from 'react-i18next';
import getQueryVariable, { getAcrValue, getQueryVariableFromUrl, getSource } from '../utility/getQueryVariable';
import redirectToWebApp from '../utility/redirectToWebApp';
import { Sso } from '../sso/Sso';
import ExternalProviders, { Providers } from '../externalproviders/ExternalProviders';
import Loader from '../utility/Loader';
import { History, Location } from 'history';
import Background from '../Background';

import './Login.scss';

export type LoginComponentProps = {
  t: any;
  history: History<void>;
  location: Location<void>;
};

type LoginComponentState = {
  username: string;
  password: string;
  errorMessage: string;
  validCredentials: boolean;
  performingLogin: boolean;
  providerId: string;
  logoCount: number;
  lastLogoPress: number;
  environmentModalOpen: boolean;
  lockoutSeconds: number;
  passwordInputType: 'password' | 'text';
  clientId: string;
  errorFromQuery: string;
  returnUrl: string;
  mobileRedirect: string;
  isMobileRedirectViewOnly: string;
  lockoutInterval: number;
  source?: string;
};

type LoginResponse = { redirectUrl: string; selectAccount: boolean };

const environmentOptions = [
  { text: 'Dev', uri: 'goformz://env/dev' },
  //Alpha was removed in 2024.  The mobile app still responds to the commented out deep link however in order to aid our testers we have removed the options from this dropdown
  //{ text: 'Alpha', uri: 'goformz://env/alpha' },
  { text: 'Beta', uri: 'goformz://env/beta' },
  { text: 'Production', uri: 'goformz://env/production' }
];


class LoginComponent extends Component<LoginComponentProps, LoginComponentState> {
  constructor(props: LoginComponentProps) {
    super(props);

    this.logIn = this.logIn.bind(this);
    this.usernameOnchange = this.usernameOnchange.bind(this);
    this.passwordOnchange = this.passwordOnchange.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.isValidCredentials = this.isValidCredentials.bind(this);
    this.onLogoPress = this.onLogoPress.bind(this);
    this.onEnvironmentModalClose = this.onEnvironmentModalClose.bind(this);
    this.getSignupSource = this.getSignupSource.bind(this);

    const errorFromQuery = getQueryVariable('error');

    this.state = {
      username: '',
      password: '',
      validCredentials: false,
      performingLogin: false,
      errorMessage: errorFromQuery || null,
      providerId: '',
      logoCount: 0,
      lastLogoPress: Date.now(),
      environmentModalOpen: false,
      lockoutSeconds: 0,
      passwordInputType: 'password',
      clientId: getQueryVariable('clientId'),
      errorFromQuery,
      returnUrl: getQueryVariable('returnUrl'),
      mobileRedirect: getQueryVariable('mobileRedirect'),
      isMobileRedirectViewOnly: getQueryVariable('isMobileRedirectViewOnly'),
      lockoutInterval: null
    };

    if (!this.state.returnUrl && !this.state.mobileRedirect) {
      redirectToWebApp();
    }
  }


  componentDidMount() {
    document.title = this.props.t('LoginTitle');

    if (this.state.mobileRedirect) {
      if (this.state.isMobileRedirectViewOnly == null) {
        const date = new Date();
        date.setMinutes(date.getMinutes() + 1);
        document.cookie = `mobile-redirect-attempt=true;expires=${date.toUTCString()};path=/`;
        window.location.replace(`goformz://idp${window.location.search}`);
      }
      return;
    }

    const { returnUrl, errorFromQuery } = this.state;

    this.setState({ source: getSource(this.state.returnUrl) });

    // if idp is set, do an automatic sso
    if (returnUrl) {
      const acr = getQueryVariableFromUrl(returnUrl, 'acr_values');
      if (acr) {
        const parts = acr.split(':');
        if (parts.length > 1 && parts[0] === 'idp') {
          this.setState({ providerId: parts[1] });
        }
      }

      const switchAccount = getQueryVariableFromUrl(returnUrl, 'switchAccount');
      //don't switch if acr values are set. acr values only get set on initial login or after an account is selected, and never on switch account
      if (switchAccount && !acr) {
        this.props.history.replace({ pathname: '/switchaccount', search: `${this.props.location.search}` });
        return;
      }
    }
  }

  getSignupSource() {
    return getAcrValue(this.state.returnUrl, 'signupsource');
  }

  usernameOnchange(e: React.ChangeEvent<HtmlInputrops>) {
    const usernameValue = e.target.value;
    this.setState({ username: usernameValue });
    this.isValidCredentials(usernameValue, this.state.password);
  }

  passwordOnchange(e: React.ChangeEvent<HtmlInputrops>) {
    const passwordValue = e.target.value;
    this.setState({ password: passwordValue });
    this.isValidCredentials(this.state.username, passwordValue);
  }

  showPassword() {
    if (this.state.passwordInputType === 'password') {
      this.setState({ passwordInputType: 'text' });
    } else {
      this.setState({ passwordInputType: 'password' });
    }
  }

  formSubmit(e: React.SyntheticEvent) {
    (document.activeElement as HTMLInputElement)?.blur();
    e.preventDefault();
    this.logIn();
  }

  isValidCredentials(email: string, password: string) {
    const pattern = new RegExp(/^(.+@.+)/i);

    if (pattern.test(email) && password.length > 0) {
      this.setState({ validCredentials: true });
      return;
    }
    this.setState({ validCredentials: false });
  }

  onLogoPress() {
    const { source, logoCount, lastLogoPress } = this.state;
    if (source && source !== 'web' && logoCount < 10) {
      const now = Date.now();
      if (now - lastLogoPress > 1000) {
        this.setState({ logoCount: 1, lastLogoPress: now });
      } else {
        this.setState({ logoCount: logoCount + 1, lastLogoPress: now, environmentModalOpen: logoCount + 1 >= 10 });
      }
      return;
    }
    window.location.assign('https://www.goformz.com');
  }

  onEnvironmentModalClose() {
    this.setState({ logoCount: 0, environmentModalOpen: false });
  }

  onExternalLogin = (providerId: Providers) => {
    this.setState({ performingLogin: true });

    //currently all external providers are oauth
    const generatedForm = document.createElement('form');
    generatedForm.action = `${window.location.origin}/authenticate/oauthlogin`;
    generatedForm.method = 'POST';

    try {
      const data = {
        returnUrl: this.state.returnUrl,
        clientId: this.state.clientId,
        environment: this.state.source,
        authorizationFailedReturnUrl: window.location.href,
        providerId
      };

      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property) && !!data[property]) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = property;
          input.value = data[property];
          generatedForm.appendChild(input);
        }
      }

      document.body.appendChild(generatedForm);
      generatedForm.submit();
    } catch (err) {
      const errorMessage = err?.response?.data?.message || this.props.t('UnknownError');
      this.setState({ performingLogin: false });
      this.setState({ errorMessage });
    }
  };

  async logIn() {
    this.setState({ performingLogin: true });
    const username = this.state.username;
    const password = this.state.password;
    const returnUrl = this.state.returnUrl;

    try {
      const { data } = await Axios<LoginResponse>('/authenticate/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          username,
          password,
          returnUrl
        }
      });

      if (!data) {
        // throw error?
        return;
      }

      // you're an IU, take you to the account selection page
      if (!!data.selectAccount) {
        this.props.history.replace({ pathname: '/accounts', search: `${this.props.location.search}` });
        return;
      }
      // you have one client or are not an IU
      window.location.replace(data.redirectUrl);
    } catch (err) {
      const errorMessage = err?.response?.data?.message || this.props.t('UnknownError');
      this.setState({ performingLogin: false });

      // too many failed attempts - timer countdown to wait for 60 seconds
      if (errorMessage === 'lockoutlimit') {
        clearInterval(this.state.lockoutInterval);
        this.setState({ lockoutSeconds: 60 });
        const timerId = setInterval(() => {
          this.setState({ lockoutSeconds: this.state.lockoutSeconds - 1 });
          if (this.state.lockoutSeconds < 0) {
            clearInterval(this.state.lockoutInterval);
          }
        }, 1000);

        this.setState({ lockoutInterval: timerId as any });
      }
      this.setState({ errorMessage });
    }
  }

  render() {
    if (this.state.performingLogin) {
      return <Loader isLoading />;
    }
    if (this.state.providerId) {
      return <Sso providerId={this.state.providerId} redirectUrl={this.state.returnUrl} clientId={this.state.clientId} />;
    }
    if (this.state.mobileRedirect) {
      const isDesktop = navigator.userAgent.match(/Windows/i);
      const clickToLaunchKey = isDesktop ? 'ClickToLaunchDesktop' : 'ClickToLaunchMobile';
      const launchNotWorkingKey = isDesktop ? 'LaunchNotWorkingDesktop' : 'LaunchNotWorkingMobile';
      return (
        <div className="login">
          <div className="content open-mobile-app">
            <div className="goformz-logo-wrapper">
              <div>
                <div className="goformz-logo" />
              </div>
            </div>
            <div className="display">
              <div className="title">{this.props.t('Launching')}</div>
              <div className="mobile-logo" />
              <div className="text">
                <div>
                  <span>
                    <Trans i18nKey={clickToLaunchKey}>
                      Click "<b>Open GoFormz Mobile Forms</b>" to launch the app.
                    </Trans>
                  </span>
                </div>
                <div>
                  <span>
                    <Trans i18nKey={launchNotWorkingKey}>
                      Not working? You can also <a href={new URL(location.href).origin}>use GoFormz in your browser</a>.
                    </Trans>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="login">
        <Background />
        <div className="content">
          {/* TODO - replace with GfLogo component */}
          <div className="logo outside-card" onClick={this.onLogoPress} />
          <div className="card">
            <div className="header">
              <span className="login-to-your-account-text">{this.props.t('LoginHeader')}</span>
            </div>

            {this.state.errorMessage ? (
              <div className="form-group">
                <div className="error-state">
                  {this.state.errorMessage === 'lockoutlimit' ? (
                    <div>
                      <div>{this.props.t('Login attempt limit reached.')}</div>
                      {this.state.lockoutSeconds > 0 ? (
                        <div>{this.props.t('Please try again in {{seconds}} seconds or', { seconds: this.state.lockoutSeconds })}</div>
                      ) : (
                        <div>{this.props.t('Please try again or')}</div>
                      )}
                      <div>
                        <Link to={`/forgotpassword?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`}>
                          {this.props.t('use this link to reset your password')}
                        </Link>
                        .
                      </div>
                    </div>
                  ) : (
                    this.state.errorMessage
                  )}
                </div>
              </div>
            ) : null}

            <div className="goformz-provider">
              <form name="login" onSubmit={this.formSubmit} className="login-form">
                <Input
                  type="email"
                  name="username"
                  id="username"
                  placeholder={this.props.t('UsernameFieldPlaceholder')}
                  value={this.state.username}
                  onChange={(e) => this.usernameOnchange(e)}
                  autoFocus
                />
                <Input
                  key={this.state.passwordInputType}
                  className="password-input"
                  type={this.state.passwordInputType}
                  name="password"
                  id="password"
                  placeholder={this.props.t('PasswordFieldPlaceholder')}
                  value={this.state.password}
                  onChange={(e) => this.passwordOnchange(e)}
                />
                <div className="password-controls">
                  <div className="show-password">
                    <Checkbox label={this.props.t('ShowPassword')} onChange={this.showPassword} checked={this.state.passwordInputType === 'text'} />
                  </div>
                  <div className="forgot-password">
                    <Link to={`/forgotpassword?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`}>{this.props.t('ForgotPassword')}</Link>
                  </div>
                </div>
                <div className="login-button">
                  <Button className="ui primary button" id="login" type="submit" disabled={!this.state.validCredentials}>
                    {this.props.t('LoginButton')}
                  </Button>
                </div>
              </form>
            </div>

            <ExternalProviders showLine={true} text={this.props.t('LoginWith')} onProviderClick={this.onExternalLogin}></ExternalProviders>
            <div className="form-secondary">
              <div className="mobile-action-buttons">
                <Link to={`/sso?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="action-button mobile">
                  {this.props.t('SSOLink')}
                </Link>
                <Link to={`/signup?returnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="action-button mobile">
                  {this.props.t('SignupLink')}
                </Link>
              </div>
            </div>
          </div>

          <div className="login-footer sso-footer">
            <form name="sso" method="post" asp-page-handler="Sso">
              <Link to={`/sso?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="login-footer-text">
                {this.props.t('SSOLink')}
              </Link>
            </form>
          </div>
          <div className="login-footer signup-footer">
            <Link to={`/signup?returnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="login-footer-text">
              {this.props.t('SignupLink')}
            </Link>
          </div>
          <div className="bottom-wrap">
            <Modal
              size="mini"
              dimmer="inverted"
              closeIcon={{ color: 'black', name: 'close' }}
              closeOnDocumentClick={false}
              closeOnDimmerClick={false}
              open={this.state.environmentModalOpen}
              onClose={this.onEnvironmentModalClose}
            >
              <Modal.Header>Select an environment</Modal.Header>
              <Modal.Content className='mobile-environment-modal-body'>
              {environmentOptions.map((option) => ( 
                <div key={`environment-button-${option.text}`} className="environment-button">
                  <a className="ui button" href={option.uri}>
                    {option.text}
                  </a>
                </div>  
              ))}
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export const Login = withTranslation()(LoginComponent);
