import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Login } from './components/login/Login';
import { Logout } from './components/logout/Logout';
import { ForgotPassword } from './components/forgotpassword/ForgotPassword';
import { ResetPassword } from './components/resetpassword/ResetPassword';
import { Sso } from './components/sso/Sso';
import ErrorPage from './components/error/ErrorPage';
import { Signup } from './components/signup/Signup';
import { SignupSurvey } from './components/signupsurvery/SignupSurvey';
import { ChangePassword } from './components/changepassword/ChangePassword';
import { SSOConnect } from './components/ssoconnect/SSOConnect';
import { SignupInvite } from './components/signupinvite/SignupInvite';
import AccountSelection from './components/accounts/AccountSelection';
import { IndependentUserSetup } from './components/independentuser/IndependentUserSetup';
import { UserSetupSurvey } from './components/signupinvitesetup/UserSetupSurvey';
import { IndependentUserSurvey } from './components/independentuser/IndependentUserSurvey';
import { IndependentUserAccept } from './components/independentuser/IndependentUserAccept';
import SwitchAccount from './components/accounts/SwitchAccount';
import { IndependentUserLogin } from './components/login/IndependentUserLogin';
import { loadSegment } from './components/utility/segment';
import { loadSift } from './components/utility/sift';

import './custom.css';
import './components/main.scss';

export default function App() {
  const location  = useLocation();
  useEffect(() => {
    loadSegment();
    loadSift();
  }, [location]);
  
  return (
    <Layout>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Route exact path="/resetpassword" component={ResetPassword} />
      <Route exact path="/changepassword" component={ChangePassword} />
      <Route exact path="/sso" component={Sso} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/signupsurvey" component={SignupSurvey} />
      <Route exact path="/ssoconnect" component={SSOConnect} />
      <Route exact path="/invite" component={SignupInvite} />
      <Route exact path="/independentusersetup" component={IndependentUserSetup} />
      <Route exact path="/independentusersurvey" component={IndependentUserSurvey} />
      <Route exact path="/usersetupsurvey" component={UserSetupSurvey} />
      <Route exact path="/independentuseraccept" component={IndependentUserAccept} />
      <Route exact path="/independentuserlogin" component={IndependentUserLogin} />
      <Route exact path="/error" component={ErrorPage} />
      <Route exact path="/accounts" component={AccountSelection} />
      <Route exact path="/switchaccount" component={SwitchAccount} />
    </Layout>
  );
}
